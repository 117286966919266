import { absoluteDevicesPath } from "core/util/routes";
import * as React from "react";
import { ReactElement } from "react";
import { FaEdit } from "react-icons/fa";
import LinkButton from "core/components/UI/LinkButton/LinkButton";

interface DeviceEditButtonProps {
    deviceId: string;
}

export function DeviceEditButton(props: DeviceEditButtonProps): ReactElement {
    return <LinkButton
        to={`${absoluteDevicesPath}/${props.deviceId}/edit`}
        variant="primary"
        size="md"
    >
        <FaEdit />
    </LinkButton>;
}
