import { useNavigate } from "react-router-dom";
import { absoluteFontPath } from "core/util/routes";
import React from "react";
import { useCreateFont } from "hooks/api/media/font/useCreateFont";
import { FontForm, FontFormFields } from "font/FontForm";

export function FontCreateForm() {
    const { sendRequest, isLoading } = useCreateFont();
    const navigate = useNavigate();

    async function onSubmit(data: FontFormFields): Promise<void> {
        const files = Array.from(data.file);
        delete data.file;
        const result = await sendRequest({
            file: files[0],
            ...data,
        });

        if (result) {
            navigate(absoluteFontPath);
        }
    }

    return <FontForm handleSubmit={onSubmit} isLoading={isLoading} />;
}
