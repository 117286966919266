import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { absoluteDevicesPath, absoluteWireguardPeerAdminPage } from "core/util/routes";
import React from "react";
import { WireguardPeerEntry } from "model/WireguardPeerEntry";

interface WireGuardPeerEntryListProps {
    wireGuardPeerEntries: WireguardPeerEntry[];
}

export function WireGuardPeerEntryList(props: WireGuardPeerEntryListProps) {
    return (
        <Table hover>
            <thead>
                <tr>
                    <th>IP Address</th>
                    <th>Public Key</th>
                    <th>Device</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                {props.wireGuardPeerEntries.map((peerEntry, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <Link
                                    className="nav nav-link"
                                    to={`${absoluteWireguardPeerAdminPage}/${peerEntry.id}`}
                                >
                                    {peerEntry.ipAddress}
                                </Link>
                            </td>
                            <td className="align-middle">
                                {peerEntry.publicKey}
                            </td>
                            <td className="align-middle">
                                {peerEntry.device?.id ? (
                                    <Link
                                        className="nav nav-link p-0"
                                        to={`${absoluteDevicesPath}/${peerEntry.device.id}`}
                                    >
                                        {peerEntry.device.id}
                                    </Link>
                                ) : (
                                    "No device set"
                                )}
                            </td>
                            <td className="align-middle">
                                {peerEntry.description}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}
