import { useNavigate } from "react-router-dom";
import { absoluteKeycloakRealmPath } from "core/util/routes";
import React from "react";
import { useCreateKeycloakRealm } from "hooks/api/keycloak-realm/useCreateKeycloakRealm";
import {
    KeycloakRealmForm,
    KeycloakRealmFormFields,
} from "keycloak-realm/KeycloakRealmForm";

export function KeycloakRealmCreateForm() {
    const { sendRequest, isLoading } = useCreateKeycloakRealm();
    const navigate = useNavigate();

    async function onSubmit(data: KeycloakRealmFormFields): Promise<void> {
        const result = await sendRequest(data);
        if (result) {
            navigate(absoluteKeycloakRealmPath);
        }
    }

    return <KeycloakRealmForm handleSubmit={onSubmit} isLoading={isLoading} />;
}
