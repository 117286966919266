import React, { ReactElement } from "react";
import { ButtonGroup, ListGroup } from "react-bootstrap";
import { FaFolder } from "react-icons/fa";
import { FolderListItem } from "model/FolderListItem";
import { Link, useSearchParams } from "react-router-dom";
import styles from "core/folder/FolderListGroupItem/FolderListGroupItem.scss";
import { FolderDeleteButton } from "core/folder/FolderDeleteButton/FolderDeleteButton";
import { FolderMoveButtonContainer } from "core/folder/FolderMoveButton/FolderMoveButtonContainer";
import { FolderType } from "model/FolderType";
import { FolderEditButton } from "core/folder/FolderEditButton/FolderEditButton";
import { GroupRole } from "core/auth/GroupRole";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { OverflowMenu } from "core/components/OverflowMenu/OverflowMenu";

interface FolderListGroupItemProps {
    folderListItem: FolderListItem;
    changeEvent: string;
    folderType: FolderType;
}

export function FolderListGroupItem(
    props: FolderListGroupItemProps
): ReactElement {
    const organizationContext = useOrganizationContext();
    const [_searchParams, setSearchParams] = useSearchParams();

    return (
        <ListGroup.Item className="d-flex justify-content-between">
            <div className={styles.main}>
                <FaFolder />
                <Link
                    className="nav-link"
                    to="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setSearchParams({
                            folderId: props.folderListItem.id,
                        });
                    }}
                >
                    {props.folderListItem.name
                        ? props.folderListItem.name
                        : props.folderListItem.id}
                </Link>
            </div>
            <div>
                <ButtonGroup>
                    <HasGroupRole
                        groupId={organizationContext}
                        role={GroupRole.EDITOR}
                    >
                        <FolderEditButton
                            folderId={props.folderListItem.id}
                            successEvent={props.changeEvent}
                        />
                        <FolderMoveButtonContainer
                            folderId={props.folderListItem.id}
                            successEvent={props.changeEvent}
                            folderType={props.folderType}
                        />

                        <OverflowMenu variant="secondary">
                            <FolderDeleteButton
                                folderId={props.folderListItem.id}
                                successEvent={props.changeEvent}
                                variant="dropdown"
                            />
                        </OverflowMenu>
                    </HasGroupRole>
                </ButtonGroup>
            </div>
        </ListGroup.Item>
    );
}
