import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import usePlugins from "hooks/usePlugins";
import PluginTemplateComponent from "core/components/Plugin/PluginTemplateComponent/PluginTemplateComponent";

function TemplatePage(): ReactElement {
    const { templates } = usePlugins();

    return (
        <Container>
            <h2>Templates</h2>
            <Link to={`simpleTemplate`}>Simple Template</Link>
            {templates.length > 0 && (
                <PluginTemplateComponent
                    template={templates[0].templateIdentifier}
                    layoutId={templates[0].layouts[0].id}
                    resourceDirectory={templates[0].resourceDirectory}
                    environment={undefined}
                    templateProps={{
                        editable: false,
                        settings: { title: "", custom: templates[0].defaultSettings || {} }
                    }}
                />
            )}
        </Container>
    );
}

export default TemplatePage;
