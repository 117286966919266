import React, { ReactElement } from "react";
import { Route } from "react-router";
import DeviceCreatePage from "device/DeviceCreatePage";
import { DeviceShowPage } from "device/DeviceShowPage";
import DeviceListPage from "device/DeviceListPage";
import DeviceEditPage from "device/DeviceEditPage";
import { Routes } from "react-router-dom";

/**
 * Routes for /devices sub pages
 */
function DeviceRouter(): ReactElement {
    return (
        <Routes>
            <Route path="register" element={<DeviceCreatePage />} />
            <Route path=":deviceId" element={<DeviceShowPage />} />
            <Route path=":deviceId/edit" element={<DeviceEditPage />} />
            <Route path="" element={<DeviceListPage />} />
        </Routes>
    );
}

export default DeviceRouter;
