import * as React from "react";
import AuthContextProvider from "services/auth/AuthContextProvider";
import EventContextProvider from "events/EventContextProvider";
import WebSocketComponent from "core/components/WebSocketComponent/WebSocketComponent";
import PluginContextProvider from "core/components/Plugin/PluginContext/PluginContextProvider";
import { UploadContextProvider } from "core/components/UploadContext/UploadContextProvider";
import { FontFace } from "core/components/FontFace";
import { TenantProfileContextProvider } from "tenant-profile/TenantProfileContextProvider";
import { Outlet } from "react-router-dom";

export function PrivateRoute() {
    return (
        <TenantProfileContextProvider>
            <EventContextProvider>
                <AuthContextProvider>
                    <UploadContextProvider>
                        <PluginContextProvider>
                            <FontFace />
                            <WebSocketComponent />
                            <Outlet />
                        </PluginContextProvider>
                    </UploadContextProvider>
                </AuthContextProvider>
            </EventContextProvider>
        </TenantProfileContextProvider>
    );
}
