import React, { ReactElement, useState } from "react";
import { useEventEmitter } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { absoluteSchedulePath } from "core/util/routes";
import Modal from "core/components/UI/Modal/Modal";
import Loader from "core/components/UI/Loader/Loader";
import Button from "core/components/UI/Button/Button";
import { FaTrash } from "react-icons/fa";
import { ScheduleDeleteModalBody } from "schedule/schedule/components/ScheduleDeleteModalBody";
import { useDeleteSchedule } from "hooks/api/schedule/useDeleteSchedule";
import { FolderListItem } from "model/FolderListItem";
import { Schedule } from "model/Schedule";
import { Dropdown } from "react-bootstrap";
import { Navigate } from "react-router-dom";

interface ScheduleDeleteButtonProps {
    schedule: FolderListItem | Schedule;
    inList: boolean;
    variant: "dropdown" | "button";
}

export function ScheduleDeleteButton(
    props: ScheduleDeleteButtonProps
): ReactElement {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const eventEmitter = useEventEmitter();
    const { sendRequest, response, isLoading } = useDeleteSchedule(
        props.schedule?.id
    );

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    const confirmDelete = (): void => {
        setLoading(true);
        sendRequest();
    };

    // If the response value is set, the delete was successful. We redirect and send event.
    if (response && !isLoading) {
        eventEmitter.emit(LocalEvents.SCHEDULES_CHANGED);

        return <Navigate to={absoluteSchedulePath} replace />;
    }

    return (
        <>
            <Modal
                show={showModal}
                onHide={closeModal}
                size={"lg"}
                title="Are you sure?"
            >
                <Modal.Body>
                    {loading && <Loader />}
                    {!loading && showModal && (
                        <ScheduleDeleteModalBody schedule={props.schedule} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"secondary"} onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        variant={"danger"}
                        onClick={confirmDelete}
                        data-cy="schedule-delete-confirm-button"
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {props.variant === "button" && (
                <Button
                    variant="danger"
                    onClick={openModal}
                    size={props.inList ? "md" : "sm"}
                    data-cy="schedule-delete-button"
                >
                    <FaTrash /> {!props.inList && "Delete schedule"}
                </Button>
            )}
            {props.variant === "dropdown" && (
                <Dropdown.Item
                    onClick={openModal}
                    className="d-flex align-items-center"
                    data-cy="schedule-delete-button"
                >
                    <FaTrash color="var(--bs-danger)" />
                    &nbsp;Delete schedule
                </Dropdown.Item>
            )}
        </>
    );
}
