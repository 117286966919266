import * as React from "react";

import { ButtonGroup, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactElement } from "react";
import { absoluteChannelsPath } from "core/util/routes";
import { FolderListItem } from "model/FolderListItem";
import { ChannelMoveButton } from "channel/list/ChannelMoveButton";
import LocalEvents from "events/LocalEvents";
import { FolderType } from "model/FolderType";
import { FolderListGroupItem } from "core/folder/FolderListGroupItem/FolderListGroupItem";
import { FolderNavigateUp } from "core/folder/FolderNavigateUp/FolderNavigateUp";
import { GroupRole } from "core/auth/GroupRole";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import DeleteChannelButton from "channel/button/DeleteChannelButton";
import { OverflowMenu } from "core/components/OverflowMenu/OverflowMenu";

interface ChannelListProps {
    channels: FolderListItem[];
    folderId?: string;
}

function ChannelList(props: ChannelListProps): ReactElement {
    const organizationContext = useOrganizationContext();

    const channelList = props.channels?.map(
        (folderListItem: FolderListItem, index: number): ReactElement => {
            if (folderListItem.type === "FOLDER") {
                return (
                    <FolderListGroupItem
                        key={index}
                        folderListItem={folderListItem}
                        changeEvent={LocalEvents.CHANNELS_CHANGED}
                        folderType={FolderType.CHANNEL}
                    />
                );
            }

            return (
                <ListGroup.Item key={index} data-cy="channels-list-item">
                    <div className="d-flex justify-content-between">
                        <div>
                            <Link
                                className="nav-link"
                                to={`${absoluteChannelsPath}/${folderListItem.id}`}
                                key={index}
                            >
                                {folderListItem.name
                                    ? folderListItem.name
                                    : folderListItem.id}
                            </Link>
                        </div>
                        <div>
                            <HasGroupRole
                                groupId={organizationContext}
                                role={GroupRole.EDITOR}
                            >
                                <ButtonGroup>
                                    <ChannelMoveButton
                                        channelId={folderListItem.id}
                                        currentFolderId={folderListItem.folder}
                                    />
                                    <OverflowMenu variant="secondary">
                                        <DeleteChannelButton
                                            channelId={folderListItem.id}
                                            variant="dropdown"
                                        />
                                    </OverflowMenu>
                                </ButtonGroup>
                            </HasGroupRole>
                        </div>
                    </div>
                </ListGroup.Item>
            );
        }
    );

    return (
        <ListGroup className="nav" data-cy="channels-list">
            <FolderNavigateUp parentFolderId={props.folderId} />
            {channelList}
        </ListGroup>
    );
}

export default ChannelList;
