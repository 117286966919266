import React, { ReactElement } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export function Page404(): ReactElement {
    return (
        <div className="page-wrap d-flex flex-row align-items-center vh-100">
            <Container>
                <Row>
                    <Col md={12} className="text-center">
                        <div className="display-1">404</div>
                        <div className="mb-4 lead">
                            The page you are looking for was not found.
                        </div>
                        <Link to="/" className="btn btn-link">
                            Back to Home
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
