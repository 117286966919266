import React, { ReactElement } from "react";
import { useCreateUser } from "user/hooks/useCreateUser";
import { UserCreateFormFields, UserForm } from "user/UserForm";
import { useNavigate } from "react-router-dom";
import { absoluteUserPath } from "core/util/routes";

export function UserCreateForm(): ReactElement {
    const { sendRequest, isLoading } = useCreateUser();
    const navigate = useNavigate();

    async function onSubmit(data: UserCreateFormFields): Promise<void> {
        const result = await sendRequest(data);
        if (result) {
            navigate(absoluteUserPath);
        }
    }

    return <UserForm handleSubmit={onSubmit} isLoading={isLoading} />;
}
