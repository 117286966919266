import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { absoluteTenantProfilePath } from "core/util/routes";
import { FaEdit } from "react-icons/fa";
import React from "react";

interface TenantThemeEditButtonProps {
    tenantProfileId: string;
}

export function TenantThemeEditButton(props: TenantThemeEditButtonProps) {
    return (
        <LinkButton
            to={`${absoluteTenantProfilePath}/${props.tenantProfileId}/theme/edit`}
            variant="primary"
            size="md"
        >
            <FaEdit /> Edit Theme
        </LinkButton>
    );
}
