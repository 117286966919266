import * as React from "react";
import { FunctionComponent } from "react";
import { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import SceneContainer from "core/components/SceneContainer/SceneContainer";

const SceneRouter: FunctionComponent = function (): ReactElement {
    // Calculate the scale of the scene based on the render width of 1920 pixels that the dashboard uses.
    const runtimeWidthScale = window.screen.width / 1920;

    return (
        <Routes>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <Route
                path={`:scene`}
            >
                <div
                    style={{
                        height: "100vh",
                        width: "100vw",
                        backgroundColor: "white",
                        fontSize: `${runtimeWidthScale * 16}px`,
                    }}
                >
                    <SceneContainer editable={false} />
                </div>
            </Route>
        </Routes>
    );
};

export default SceneRouter;
