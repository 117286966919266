import React, { ReactElement } from "react";
import { useCreateGroup } from "group/hooks/useCreateGroup";
import { useNavigate } from "react-router-dom";
import { absoluteGroupPath } from "core/util/routes";
import { GroupForm, GroupFormFields } from "group/GroupForm";
import { Loader } from "@castia/sdk";
import Error from "core/components/UI/Error/Error";

export function GroupCreateForm(): ReactElement {
    const { sendRequest, isLoading, error } = useCreateGroup();
    const navigate = useNavigate();

    async function onSubmit(data: GroupFormFields): Promise<void> {
        const result = await sendRequest(data);
        if (result) {
            navigate(absoluteGroupPath);
        }
    }

    return (
        <>
            {isLoading && <Loader />}
            {error && <Error>{error.message}</Error>}
            <GroupForm handleSubmit={onSubmit} isLoading={isLoading} />
        </>
    );
}
