import * as React from "react";
import { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import TemplatePage from "../../pages/TemplatePage/TemplatePage";
import AuthContextProvider from "services/auth/AuthContextProvider";
import PluginContextProvider from "core/components/Plugin/PluginContext/PluginContextProvider";

function TemplateRouter(): ReactElement {
    return (
        <div>
            <main role="main">
                <Routes>
                    <Route path={``}>
                        <AuthContextProvider>
                            <PluginContextProvider>
                                <TemplatePage />
                            </PluginContextProvider>
                        </AuthContextProvider>
                    </Route>
                </Routes>
            </main>
        </div>
    );
}

export default TemplateRouter;
