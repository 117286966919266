import { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import { Paginated, useEventListener } from "@castia/sdk";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";
import { useFetchPaginated } from "hooks/useFetchPaginated";
import { SceneFolderListItem } from "model/SceneFolderListItem";

export const defaultScenePageSize = 10;

/**
 * Fetch the channel list. Subscribe to the channel Change event and reload the data if it gets triggered.
 */
function useFetchAllScenesPaginated(folderId?: string, scenePageSize?: number): FetchData<Paginated<SceneFolderListItem>> {
    const fetch = useFetchPaginated<SceneFolderListItem>(
        `${environment.apiBaseUrl}/api/v1/scenes`,
        scenePageSize || defaultScenePageSize,
        1,
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.SCENES_CHANGED, eventCallback);

    return fetch;
}

export default useFetchAllScenesPaginated;
