import React, { ReactElement } from "react";
import { Wizard } from "core/components/Form/Wizard/Wizard";
import DeviceNamePage from "device/create/wizard/DeviceNamePage";
import { Col, Container } from "react-bootstrap";
import useUpdateDevice from "hooks/api/device/useUpdateDevice";
import { absoluteDevicesPath } from "core/util/routes";
import { DeviceContentPage } from "device/create/wizard/DeviceContentPage";
import { ContentType } from "device/edit/content/DeviceContentSource";
import { Navigate } from "react-router-dom";

interface DeviceRegistrationWizardFields {
    deviceName: string;
    contentId: string;
    contentType: ContentType;
}

interface DeviceSettingsWizardProps {
    deviceId: string;
}

/**
 * Wizard to configure the initial device settings after registration.
 * @param props
 * @constructor
 */
function DeviceSettingsWizard(props: DeviceSettingsWizardProps): ReactElement {
    const { sendRequest, response } = useUpdateDevice(props.deviceId);

    function submit(values: DeviceRegistrationWizardFields): void {
        sendRequest({
            name: values.deviceName,
            contentId: values.contentId ? values.contentId : undefined,
            contentType: values.contentId ? values.contentType : undefined
        });
    }

    // If the response is set, the update was successful and we can redirect away from the wizard.
    if (response) {
        return <Navigate to={`${absoluteDevicesPath}/${props.deviceId}`} replace />;
    }

    return (
        <Container>
            <Col md={{ span: 4, offset: 4 }}>
                <Wizard<DeviceRegistrationWizardFields>
                    onSubmit={submit}
                    initialValues={{
                        deviceName: "",
                        contentId: "",
                        contentType: "SCHEDULE",
                    }}
                >
                    <DeviceNamePage />
                    <DeviceContentPage />
                </Wizard>
            </Col>
        </Container>
    );
}

export default DeviceSettingsWizard;
