import * as React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useDebouncedValue } from "hooks/useDebouncedValue";
import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";

interface SearchFilterProps {
    className?: string;
}

export function SearchFilter(props: SearchFilterProps) {
    const [searchValue, setSearchValue] = useState<string>("");
    const debouncedValue = useDebouncedValue<string>(searchValue, 800);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.has("search")) {
            setSearchValue(searchParams.get("search"));
        }
    }, []);

    useEffect(() => {
        if (!searchParams.has("search")) {
            setSearchValue("");
        }
    }, [searchParams.get("search")]);

    useEffect(() => {
        if (debouncedValue === undefined) {
            return;
        }
        if (debouncedValue !== null) {
            if (debouncedValue === "") {
                searchParams.delete("search");
                setSearchParams(searchParams, { replace: true });
            } else {
                setSearchParams({ search: debouncedValue }, { replace: true });
            }
        }
    }, [debouncedValue]);

    return (
        <InputGroup
            className={props.className}
            style={{
                width: "auto",
            }}
        >
            <Form.Control
                name="search"
                type="string"
                placeholder="Search"
                onChange={(event) => {
                    setSearchValue(event.currentTarget.value);
                }}
                value={searchValue}
            />
            <Button
                aria-label="clear search"
                variant="light"
                onClick={() => {
                    searchParams.delete("search");
                    setSearchParams(searchParams, { replace: true });
                }}
            >
                <FaTimes />
            </Button>
        </InputGroup>
    );
}
