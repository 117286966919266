import { Route } from "react-router";
import React, { ReactElement } from "react";
import { FontCreatePage } from "font/FontCreatePage";
import { FontPage } from "font/FontPage";
import { Routes } from "react-router-dom";

export function FontRouter(): ReactElement {
    return (
        <Routes>
            <Route path="create" element={<FontCreatePage />} />
            <Route path="" element={<FontPage />} />
        </Routes>
    );
}
