import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { UserCreateFormFields, UserForm } from "user/UserForm";
import { absoluteUserPath } from "core/util/routes";
import { usePatchUser } from "user/hooks/usePatchUser";
import { User } from "model/User";

interface UserEditFormProps {
    user: User;
}

export function UserEditForm(props: UserEditFormProps): ReactElement {
    const { sendRequest, isLoading } = usePatchUser(props.user.id);
    const navigate = useNavigate();

    async function onSubmit(data: UserCreateFormFields): Promise<void> {
        const result = await sendRequest(data);
        if (result) {
            navigate(`${absoluteUserPath}/${props.user.id}`);
        }
    }

    return (
        <UserForm
            handleSubmit={onSubmit}
            isLoading={isLoading}
            existingUser={props.user}
        />
    );
}
