import React, { ReactElement } from "react";
import { absoluteUserPath } from "core/util/routes";
import { DeleteWithConfirmButton } from "core/components/button/DeleteWithConfirmButton";
import LocalEvents from "events/LocalEvents";

interface UserDeleteButtonProps {
    userId: string;
}

export function UserDeleteButton(props: UserDeleteButtonProps): ReactElement {
    return (
        <DeleteWithConfirmButton
            apiPath={`api/v2/users/${props.userId}`}
            title={`Delete User`}
            message={"Are you sure you want to delete this user?"}
            successRedirectPath={absoluteUserPath}
            successEvent={LocalEvents.USERS_CHANGED}
        />
    );
}
