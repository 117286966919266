import React from "react";
import DeviceRegistrationForm from "device/create/DeviceRegistrationForm";
import styles from "device/DeviceCreatePage.scss";
import Fullscreen from "core/components/Fullscreen/Fullscreen";
import { FaTimes } from "react-icons/fa";
import DeviceSettingsWizard from "device/create/wizard/DeviceRegistrationWizard";
import Button from "core/components/UI/Button/Button";
import { absoluteDevicesPath } from "core/util/routes";
import { Navigate } from "react-router-dom";

interface DeviceCreatePageState {
    registrationComplete: boolean;
    deviceId: string;
    close: boolean;
}

class DeviceCreatePage extends React.Component<unknown, DeviceCreatePageState> {
    public constructor(props: unknown) {
        super(props);
        this.state = {
            registrationComplete: false,
            deviceId: null,
            close: false,
        };
    }

    private registrationComplete(deviceId: string): void {
        this.setState({
            registrationComplete: true,
            deviceId: deviceId,
        });
    }

    private closeForm(): void {
        this.setState({ close: true });
    }

    public render(): React.ReactElement {
        if (this.state.close) {
            return <Navigate to={absoluteDevicesPath} replace />;
        }

        return (
            <Fullscreen>
                <div className={styles.centeredContainer}>
                    <div className={styles.form}>
                        {!this.state.registrationComplete ? (
                            <DeviceRegistrationForm
                                onComplete={this.registrationComplete.bind(
                                    this
                                )}
                            />
                        ) : (
                            <DeviceSettingsWizard
                                deviceId={this.state.deviceId}
                            />
                        )}
                    </div>
                </div>
                <Button
                    className={styles.closeButton}
                    variant={"link"}
                    size={"lg"}
                    onClick={this.closeForm.bind(this)}
                >
                    <FaTimes />
                </Button>
            </Fullscreen>
        );
    }
}

export default DeviceCreatePage;
