import { NewsForm, NewsFormFields } from "news/NewsForm";
import { useNavigate } from "react-router-dom";
import { absoluteNewsPath } from "core/util/routes";
import { useCreateNewsItem } from "hooks/api/news/useCreateNewsItem";
import React from "react";

export function NewsCreateForm() {
    const { sendRequest, isLoading } = useCreateNewsItem();
    const navigate = useNavigate();

    async function onSubmit(data: NewsFormFields): Promise<void> {
        const result = await sendRequest(data);
        if (result) {
            navigate(absoluteNewsPath);
        }
    }

    return <NewsForm handleSubmit={onSubmit} isLoading={isLoading} />;
}
