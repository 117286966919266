import React, { ReactElement, useState } from "react";
import Button from "core/components/UI/Button/Button";
import { FaTrash } from "react-icons/fa";
import Modal from "core/components/UI/Modal/Modal";
import Loader from "core/components/UI/Loader/Loader";
import DeleteSceneModalBody from "scene/ScenesEditorPage/components/DeleteSceneButton/DeleteSceneModalBody/DeleteSceneModalBody";
import useDeleteScene from "hooks/api/scene/useDeleteScene";
import { absoluteScenesPath } from "core/util/routes";
import { Dropdown } from "react-bootstrap";
import { Navigate } from "react-router-dom";

interface SceneDeleteButtonProps {
    sceneId: string;
    variant: "button" | "dropdown";
}

/**
 * Button which deletes a scene via a 'are you sure' popup.
 * @param props
 * @constructor
 */
function DeleteSceneButton(props: SceneDeleteButtonProps): ReactElement {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { sendRequest, response, isLoading } = useDeleteScene(props.sceneId);

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    const confirmDelete = (): void => {
        setLoading(true);
        sendRequest();
    };

    // If the response value is set, the delete was successful. We redirect and send event.
    if (response && !isLoading) {
        return <Navigate to={absoluteScenesPath} replace />;
    }

    return (
        <>
            {props.variant === "button" && (
                <Button
                    variant="danger"
                    onClick={openModal}
                    title="Delete scene"
                    data-cy="scene-delete-button"
                >
                    <FaTrash />
                </Button>
            )}
            {props.variant === "dropdown" && (
                <Dropdown.Item
                    onClick={openModal}
                    className="d-flex align-items-center"
                    data-cy="scene-delete-button"
                >
                    <FaTrash color="var(--bs-danger)" />
                    &nbsp;Delete scene
                </Dropdown.Item>
            )}
            <Modal
                show={showModal}
                onHide={closeModal}
                size={"lg"}
                title="Are you sure?"
            >
                <Modal.Body>
                    {loading && <Loader />}
                    {!loading && showModal && (
                        <DeleteSceneModalBody sceneId={props.sceneId} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"secondary"} onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        variant={"danger"}
                        onClick={confirmDelete}
                        data-cy="scene-confirm-delete-button"
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DeleteSceneButton;
