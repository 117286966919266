import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { absoluteKeycloakRealmPath } from "core/util/routes";
import { FaEdit } from "react-icons/fa";
import React from "react";

interface KeycloakRealmEditButtonProps {
    keycloakRealmId: string;
}

export function KeycloakRealmEditButton(props: KeycloakRealmEditButtonProps) {
    return (
        <LinkButton
            to={`${absoluteKeycloakRealmPath}/${props.keycloakRealmId}/edit`}
            variant="primary"
            size="md"
        >
            <FaEdit />
        </LinkButton>
    );
}
