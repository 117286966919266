import React, { useEffect } from "react";
import { ReactElement } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchOneUser } from "user/hooks/useFetchOneUser";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import PageLayoutHeader from "core/components/UI/PageLayout/PageLayoutHeader/PageLayoutHeader";
import PageLayoutBody from "core/components/UI/PageLayout/PageLayoutBody/PageLayoutBody";
import PageLayoutHeaderTitle from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderTitle/PageLayoutHeaderTitle";
import PageLayoutHeaderSecondary from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderSecondary/PageLayoutHeaderSecondary";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { UserEditButton } from "user/button/UserEditButton";
import { UserDeleteButton } from "user/button/UserDeleteButton";
import { UserOverviewCard } from "user/show/UserOverviewCard";
import { UserGroupsCard } from "user/show/UserGroupsCard";
import { absoluteUserPath } from "core/util/routes";
import { IsAdmin } from "core/auth/IsAdmin";
import { UserProfileEditButton } from "./button/UserProfileEditButton";

interface UserShowPageProps {
    isProfile?: boolean;
}

export function UserShowPage(props: UserShowPageProps): ReactElement {
    const { userId } = useParams();
    const { response, isLoading, error, refreshData } = useFetchOneUser(
        userId,
        props.isProfile
    );
    const navigate = useNavigate();

    useEffect(() => {
        if (error?.status === 404) {
            navigate(absoluteUserPath);
        }
    }, [error]);

    return (
        <PageLayout>
            <ResponseErrorHandler
                isLoading={isLoading}
                error={error}
                retryAction={() => refreshData()}
            >
                {response && (
                    <>
                        <PageLayoutHeader>
                            <PageLayoutHeaderTitle>
                                {response.email}
                            </PageLayoutHeaderTitle>
                            <PageLayoutHeaderSecondary>
                                <ButtonGroup>
                                    {props.isProfile ? (
                                        <UserProfileEditButton />
                                    ) : (
                                        <UserEditButton userId={response.id} />
                                    )}
                                    <IsAdmin>
                                        <UserDeleteButton
                                            userId={response.id}
                                        />
                                    </IsAdmin>
                                </ButtonGroup>
                            </PageLayoutHeaderSecondary>
                        </PageLayoutHeader>
                        <PageLayoutBody>
                            <Row>
                                <Col md={4}>
                                    <UserOverviewCard user={response} />
                                </Col>
                                <Col md={8}>
                                    <UserGroupsCard
                                        userId={response.id}
                                        groups={response.groups}
                                        email={response.email}
                                        isProfile={props.isProfile}
                                    />
                                </Col>
                            </Row>
                        </PageLayoutBody>
                    </>
                )}
            </ResponseErrorHandler>
        </PageLayout>
    );
}
