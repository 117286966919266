import React, { ReactElement } from "react";
import { absoluteGroupPath } from "core/util/routes";
import { DeleteWithConfirmButton } from "core/components/button/DeleteWithConfirmButton";
import LocalEvents from "events/LocalEvents";

interface UserDeleteButtonProps {
    groupId: string;
}

export function GroupDeleteButton(props: UserDeleteButtonProps): ReactElement {
    return (
        <DeleteWithConfirmButton
            apiPath={`api/v2/groups/${props.groupId}`}
            title="WARNING: Delete group"
            message="Are you sure you want to permanently delete this group and ALL of its content?"
            successRedirectPath={absoluteGroupPath}
            successEvent={LocalEvents.GROUPS_CHANGED}
        />
    );
}
