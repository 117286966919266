import React, { ReactElement } from "react";
import { FaEdit } from "react-icons/fa";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { absoluteUserPath } from "core/util/routes";

interface UserEditButtonProps {
    userId: string;
}

export function UserEditButton(props: UserEditButtonProps): ReactElement {
    return (
        <LinkButton
            to={`${absoluteUserPath}/${props.userId}/edit`}
            variant="primary"
            size="md"
        >
            <FaEdit />
        </LinkButton>
    );
}
