import { Route } from "react-router";
import React, { ReactElement } from "react";
import { WireGuardPeerListPage } from "wireguard-peer/WireGuardPeerListPage";
import { Routes } from "react-router-dom";

export function WireguardPeerRouter(): ReactElement {
    return (
        <Routes>
            <Route path="" element={<WireGuardPeerListPage />} />
        </Routes>
    );
}
