import { User } from "model/User";
import React from "react";
import { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { absoluteUserPath } from "core/util/routes";
import styles from "device/list/DeviceList.scss";
import { UserDeleteButton } from "user/button/UserDeleteButton";

interface UserListProps {
    users: User[];
}

export function UserList(props: UserListProps): ReactElement {
    return (
        <Table hover>
            <thead>
                <tr>
                    <th>E-mail</th>
                    <th>Name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.users.map((user, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <Link
                                    className="nav nav-link"
                                    to={`${absoluteUserPath}/${user.id}`}
                                >
                                    {user.email}
                                </Link>
                            </td>
                            <td className={styles.connectedColumn}>
                                {user.firstName} {user.lastName}
                            </td>
                            <td>
                                <UserDeleteButton userId={user.id} />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}
