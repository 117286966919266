import { Route } from "react-router";
import React, { ReactElement } from "react";
import { TenantProfileListPage } from "tenant-profile/TenantProfileListPage";
import { TenantProfileCreatePage } from "tenant-profile/TenantProfileCreatePage";
import { TenantProfileEditPage } from "tenant-profile/TenantProfileEditPage";
import { TenantProfileShowPage } from "tenant-profile/TenantProfileShowPage";
import { TenantThemeEditPage } from "tenant-profile/theme/TenantThemeEditPage";
import { Routes } from "react-router-dom";

export function TenantProfileRouter(): ReactElement {
    return (
        <Routes>
            <Route path="create" element={<TenantProfileCreatePage />} />
            <Route
                path=":tenantProfileId"
                element={<TenantProfileShowPage />}
            />
            <Route
                path=":tenantProfileId/edit"
                element={<TenantProfileEditPage />}
            />
            <Route
                path=":tenantProfileId/theme/edit"
                element={<TenantThemeEditPage />}
            />
            <Route path="" element={<TenantProfileListPage />} />
        </Routes>
    );
}
