import { Route } from "react-router";
import React, { ReactElement } from "react";
import { UserListPage } from "user/UserListPage";
import { UserCreatePage } from "user/UserCreatePage";
import { UserShowPage } from "user/UserShowPage";
import { UserEditPage } from "user/UserEditPage";
import { Routes } from "react-router-dom";

export function UserRouter(): ReactElement {
    return (
        <Routes>
            <Route path="create" element={<UserCreatePage />} />
            <Route path=":userId" element={<UserShowPage />} />
            <Route path=":userId/edit" element={<UserEditPage />} />
            <Route path="" element={<UserListPage />} />
        </Routes>
    );
}
