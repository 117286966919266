import React, { ReactElement } from "react";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { absoluteUserPath } from "core/util/routes";
import { FaPlus } from "react-icons/fa";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { UserListContainer } from "user/list/UserListContainer";

export function UserListPage(): ReactElement {
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Users</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <LinkButton
                        to={`${absoluteUserPath}/create`}
                        variant="primary"
                        size="md"
                    >
                        <FaPlus /> Create User
                    </LinkButton>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <UserListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}
