import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { absoluteTenantProfilePath } from "core/util/routes";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import PageLayoutHeader from "core/components/UI/PageLayout/PageLayoutHeader/PageLayoutHeader";
import PageLayoutHeaderTitle from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderTitle/PageLayoutHeaderTitle";
import PageLayoutHeaderSecondary from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderSecondary/PageLayoutHeaderSecondary";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import PageLayoutBody from "core/components/UI/PageLayout/PageLayoutBody/PageLayoutBody";
import { useFetchOneTenantProfile } from "hooks/api/tenant-profile/useFetchOneTenantProfile";
import { IsSysAdmin } from "core/auth/IsSysAdmin";
import { TenantProfileOverviewCard } from "tenant-profile/show/TenantProfileOverviewCard";
import { KeycloakRealmCard } from "keycloak-realm/show/KeycloakRealmCard";
import { TenantProfileEditButton } from "tenant-profile/button/TenantProfileEditButton";
import { TenantProfileDeleteButton } from "tenant-profile/button/TenantProfileDeleteButton";
import { TenantThemeCard } from "tenant-profile/theme/show/TenantThemeCard";

export function TenantProfileShowPage() {
    const { tenantProfileId } = useParams();
    const { response, isLoading, error, refreshData } =
        useFetchOneTenantProfile(tenantProfileId);
    const navigate = useNavigate();

    useEffect(() => {
        if (error?.status === 404) {
            navigate(absoluteTenantProfilePath);
        }
    }, [error]);

    return (
        <IsSysAdmin>
            <PageLayout>
                <ResponseErrorHandler
                    isLoading={isLoading}
                    error={error}
                    retryAction={() => refreshData()}
                >
                    {response && (
                        <>
                            <PageLayoutHeader>
                                <PageLayoutHeaderTitle>
                                    {response.dashboardDomain}
                                </PageLayoutHeaderTitle>
                                <PageLayoutHeaderSecondary>
                                    <ButtonGroup>
                                        <TenantProfileEditButton
                                            tenantProfileId={tenantProfileId}
                                        />
                                        <TenantProfileDeleteButton
                                            tenantProfileId={tenantProfileId}
                                        />
                                    </ButtonGroup>
                                </PageLayoutHeaderSecondary>
                            </PageLayoutHeader>
                            <PageLayoutBody>
                                <Row>
                                    <Col md={4}>
                                        <TenantProfileOverviewCard
                                            tenantProfile={response}
                                        />
                                    </Col>
                                    <IsSysAdmin>
                                        <Col md={8}>
                                            <KeycloakRealmCard
                                                keycloakRealm={
                                                    response.keycloakRealm
                                                }
                                            />
                                        </Col>
                                    </IsSysAdmin>
                                </Row>
                                <Row>
                                    <Col md={12} className={"mt-4"}>
                                        <TenantThemeCard
                                            tenantProfileId={tenantProfileId}
                                        />
                                    </Col>
                                </Row>
                            </PageLayoutBody>
                        </>
                    )}
                </ResponseErrorHandler>
            </PageLayout>
        </IsSysAdmin>
    );
}
