import { ChannelField } from "core/components/fields/ChannelField";
import React, { ReactElement } from "react";
import { ContentType } from "device/edit/content/DeviceContentSource";
import { SceneField } from "core/components/fields/SceneField";
import { ScheduleField } from "core/components/fields/ScheduleField";
import { Link } from "react-router-dom";
import { absoluteDevicesPath } from "core/util/routes";

interface ContentFieldProps {
    contentId: string;
    contentType: ContentType;
    deviceId: string;
}

export function ContentField(props: ContentFieldProps): ReactElement {
    if (props.contentType === "CHANNEL") {
        return <ChannelField channelId={props.contentId} />;
    }
    if (props.contentType === "SCENE") {
        return <SceneField sceneId={props.contentId} />;
    }
    if (props.contentType === "SCHEDULE") {
        return <ScheduleField scheduleId={props.contentId} />;
    }

    return <span><Link to={`${absoluteDevicesPath}/${props.deviceId}/edit`}>No content set, click to configure</Link></span>;
}
