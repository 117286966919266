import { Route } from "react-router";
import React, { ReactElement } from "react";
import { UserShowPage } from "user/UserShowPage";
import { UserProfileEditPage } from "user/UserProfileEditPage";
import { Routes } from "react-router-dom";

export function UserProfileRouter(): ReactElement {
    return (
        <Routes>
            <Route path="edit" element={<UserProfileEditPage />} />
            <Route path="" element={<UserShowPage isProfile={true} />} />
        </Routes>
    );
}
