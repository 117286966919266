import { useNavigate } from "react-router-dom";
import { absoluteTenantProfilePath } from "core/util/routes";
import React from "react";
import { usePatchTheme } from "hooks/api/theme/usePatchTheme";
import { TenantTheme } from "model/TenantTheme";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import { TextInput } from "core/components/input/TextInput";
import { FieldNamesMarkedBoolean } from "react-hook-form/dist/types/form";
import { FileInput } from "core/components/input/FileInput";

const MAX_FILE_SIZE_LOGO = 204800; //200KB
const MAX_FILE_SIZE_FAVICON = 52428; //52KB

const validFileExtensions = ["png", "jpg", "jpeg", "gif"];

function isValidFileType(fileName: string) {
    return (
        fileName && validFileExtensions.indexOf(fileName.split(".").pop()) > -1
    );
}

interface TenantThemeEditFormProps {
    theme: TenantTheme;
}

const schema = yup.object({
    themeVariables: yup.object().shape({
        primary: yup.string(),
        secondary: yup.string(),
        success: yup.string(),
        info: yup.string(),
        warning: yup.string(),
        danger: yup.string(),
    }),
    logo: yup
        .mixed()
        .optional()
        .test(
            "is-valid-type",
            `Only ${validFileExtensions.join(", ")} files are allowed.`,
            (value: FileList) => {
                if (value.length === 0) return true;
                return isValidFileType(
                    value && value.length > 0 && value[0].name.toLowerCase()
                );
            }
        )
        .test(
            "is-valid-size",
            "The maximum allowed size for the logo is 200KB",
            (value: FileList) => {
                if (value.length === 0) return true;
                return (
                    value &&
                    value.length > 0 &&
                    value[0].size <= MAX_FILE_SIZE_LOGO
                );
            }
        ),
    favicon: yup
        .mixed()
        .optional()
        .test(
            "is-valid-type",
            `Only ${validFileExtensions.join(", ")} files are allowed.`,
            (value: FileList) => {
                if (value.length === 0) return true;
                return isValidFileType(
                    value && value.length > 0 && value[0].name.toLowerCase()
                );
            }
        )
        .test(
            "is-valid-size",
            "The maximum allowed size for the logo is 50KB",
            (value: FileList) => {
                if (value.length === 0) return true;
                return (
                    value &&
                    value.length > 0 &&
                    value[0].size <= MAX_FILE_SIZE_FAVICON
                );
            }
        ),
});

interface TenantThemeFormFields {
    themeVariables: {
        primary: string;
        secondary: string;
        success: string;
        info: string;
        warning: string;
        danger: string;
    };
    logo: any;
    favicon: any;
    applicationName: string;
}

interface NestedDirtyFields {
    [key: string]: boolean | NestedDirtyFields;
}

interface NestedData {
    [key: string]: any;
}

const getDirtyData = <T extends NestedData>(
    data: T,
    dirtyFields: NestedDirtyFields
): Partial<T> => {
    return Object.keys(dirtyFields).reduce((acc: any, key: string) => {
        if (dirtyFields[key] === true) {
            acc[key] = data[key]; // It's a dirty field, grab the data
        } else if (
            typeof dirtyFields[key] === "object" &&
            dirtyFields[key] !== null
        ) {
            // It's a nested object, recurse
            acc[key] = getDirtyData(
                data[key],
                dirtyFields[key] as NestedDirtyFields
            );
        }

        return acc;
    }, {});
};

export function TenantThemeEditForm(props: TenantThemeEditFormProps) {
    const { sendRequest, isLoading } = usePatchTheme(
        props.theme.tenantProfileId
    );
    const navigate = useNavigate();

    const form = useForm<TenantThemeFormFields>({
        resolver: yupResolver(schema),
        defaultValues: {
            applicationName: props.theme.applicationName,
            themeVariables: { ...props.theme.themeVariables },
        },
    });

    async function onSubmit(
        data: TenantThemeFormFields,
        dirtyFields: FieldNamesMarkedBoolean<TenantThemeFormFields>
    ): Promise<void> {
        const dirtyData = getDirtyData(data, dirtyFields);

        const { logo, favicon, ...rest } = dirtyData;
        const requestPayload: any = {
            data: JSON.stringify(rest),
        };
        if (logo) {
            const logos = Array.from(logo);
            delete data.logo;
            requestPayload.logo = logos[0];
        }
        if (favicon) {
            const favicons = Array.from(favicon);
            delete data.favicon;
            requestPayload.favicon = favicons[0];
        }

        const result = await sendRequest(requestPayload);
        if (result) {
            navigate(`${absoluteTenantProfilePath}/${props.theme.tenantProfileId}`);
        }
    }
    const { dirtyFields } = form.formState;

    return (
        <FormProvider {...form}>
            <Form
                onSubmit={form.handleSubmit(
                    (data) => {
                        onSubmit(data, dirtyFields);
                    },
                    (errors, _event) => {
                        console.error("errors", errors);
                    }
                )}
            >
                <Tabs defaultActiveKey="colors" id="theme-tabs">
                    <Tab
                        eventKey="colors"
                        title="Colors"
                        className="bg-white p-4"
                    >
                        <TextInput
                            name="themeVariables.primary"
                            label="Primary Color"
                            type="color"
                        />
                        <TextInput
                            name="themeVariables.secondary"
                            label="Secondary Color"
                            type="color"
                        />
                        <TextInput
                            name="themeVariables.success"
                            label="Success Color"
                            type="color"
                        />
                        <TextInput
                            name="themeVariables.info"
                            label="Info Color"
                            type="color"
                        />
                        <TextInput
                            name="themeVariables.warning"
                            label="Warning Color"
                            type="color"
                        />
                        <TextInput
                            name="themeVariables.danger"
                            label="Danger Color"
                            type="color"
                        />
                    </Tab>
                    <Tab
                        eventKey="branding"
                        title="Branding"
                        className="bg-white p-4"
                    >
                        <FileInput name="logo" label="Logo File" />

                        <FileInput name="favicon" label="Favicon file" />

                        <TextInput
                            name="applicationName"
                            label="Application Name"
                        />
                    </Tab>
                </Tabs>

                <Button
                    variant="primary"
                    type="submit"
                    disabled={isLoading}
                    className="ms-4 mt-2"
                >
                    Save
                </Button>
            </Form>
        </FormProvider>
    );
}
