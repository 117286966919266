import * as yup from "yup";
import { FieldNamesMarkedBoolean } from "react-hook-form/dist/types/form";
import { KeycloakRealm } from "model/KeycloakRealm";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Button, Form } from "react-bootstrap";
import { TextInput } from "core/components/input/TextInput";
import React from "react";

export interface KeycloakRealmFormFields {
    realmName: string;
    apiClientName: string;
    apiClientSecret: string;
    dashboardClientName: string;
    url: string;
}

const schema = yup.object({
    realmName: yup.string().required("Realm Name is a required field"),
    apiClientName: yup.string().required("Api Client Name is a required field"),
    apiClientSecret: yup
        .string()
        .when("$isCreate", {
            is: true,
            then: (innerSchema) =>
                innerSchema.required("Api Client Secret is a required field"),
        })
        .transform((secret) => {
            return secret || undefined;
        }),
    dashboardClientName: yup
        .string()
        .required("Dashboard Client Name is a required field"),
    url: yup.string().required("Url is a required field"),
});

interface KeycloakRealmFormProps {
    existingKeycloakRealm?: KeycloakRealm;
    handleSubmit: (
        data: KeycloakRealmFormFields,
        dirtyFields: FieldNamesMarkedBoolean<KeycloakRealmFormFields>
    ) => void;
    isLoading: boolean;
}

export function KeycloakRealmForm(props: KeycloakRealmFormProps) {
    const isCreate = !props.existingKeycloakRealm;
    const form = useForm<KeycloakRealmFormFields>({
        resolver: yupResolver(schema, {
            context: { isCreate: isCreate },
        }),
        defaultValues: props.existingKeycloakRealm
            ? props.existingKeycloakRealm
            : {},
    });
    const { dirtyFields } = form.formState;

    return (
        <FormProvider {...form}>
            <Form
                onSubmit={form.handleSubmit(
                    (data) => {
                        props.handleSubmit(data, dirtyFields);
                    },
                    (errors, _event) => {
                        console.error("errors", errors);
                    }
                )}
            >
                <TextInput name="realmName" label="Realm Name" />
                <TextInput name="apiClientName" label="API Client Name" />
                <TextInput name="apiClientSecret" label="API Client Secret" />
                <TextInput
                    name="dashboardClientName"
                    label="Dashboard Client Name"
                />
                <TextInput name="url" label="Keycloak URL" />

                <Button
                    variant="primary"
                    type="submit"
                    disabled={props.isLoading}
                >
                    Save
                </Button>
            </Form>
        </FormProvider>
    );
}
