import React, { ReactElement } from "react";
import { Route } from "react-router";
import { GroupListPage } from "group/GroupListPage";
import { GroupCreatePage } from "group/GroupCreatePage";
import { GroupEditPage } from "group/GroupEditPage";
import { GroupShowPage } from "group/GroupShowPage";
import { Routes } from "react-router-dom";

export function GroupRouter(): ReactElement {
    return (
        <Routes>
            <Route path="create" element={<GroupCreatePage />} />
            <Route path=":groupId" element={<GroupShowPage />} />
            <Route path=":groupId/edit" element={<GroupEditPage />} />
            <Route path="" element={<GroupListPage />} />
        </Routes>
    );
}
