import { Route } from "react-router";
import React, { ReactElement } from "react";
import { NewsCreatePage } from "news/NewsCreatePage";
import { NewsListPage } from "news/NewsListPage";
import { NewsEditPage } from "news/NewsEditPage";
import { Routes } from "react-router-dom";

export function NewsRouter(): ReactElement {
    return (
        <Routes>
            <Route path="create" element={<NewsCreatePage />} />
            <Route path=":newsItemId/edit" element={<NewsEditPage />} />
            <Route path="" element={<NewsListPage />} />
        </Routes>
    );
}
