import React, { forwardRef, MutableRefObject, ReactElement } from "react";
import * as RB from "react-bootstrap";

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean;
    variant?:
        | "primary"
        | "secondary"
        | "success"
        | "danger"
        | "warning"
        | "info"
        | "dark"
        | "light"
        | "link"
        | "outline-primary";
    size?: "sm" | "lg" | "md";
}

/**
 * Convert the size to a size that bootstrap allows. "md" is the default in bootstrap which can be achieved by passing
 * in undefined.
 * @param size
 */
function bootstrapSize(size?: string): "sm" | "lg" | undefined {
    if (size === "sm") {
        return "sm";
    } else if (size === "lg") {
        return "lg";
    }
    return undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Button = (
    props: ButtonProps,
    ref: MutableRefObject<HTMLButtonElement>
): ReactElement => {
    const { size, ...rest } = props;
    const bsSize = bootstrapSize(size);

    return (
        <RB.Button
            ref={ref}
            size={bsSize}
            {...rest}
        >
            {props.children}
        </RB.Button>
    );
};

export default forwardRef(Button);
