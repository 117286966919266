import React, { ReactElement } from "react";
import { FaEdit } from "react-icons/fa";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { absoluteProfilePath } from "core/util/routes";

export function UserProfileEditButton(): ReactElement {
    return (
        <LinkButton to={`${absoluteProfilePath}/edit`} variant="primary" size="md">
            <FaEdit />
        </LinkButton>
    );
}
