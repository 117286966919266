import * as React from "react";
import { ReactElement } from "react";

import { ButtonGroup, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteSceneButton from "scene/ScenesEditorPage/components/DeleteSceneButton/DeleteSceneButton";
import { absoluteScenesPath } from "core/util/routes";
import { FolderListItem } from "model/FolderListItem";
import { FolderListGroupItem } from "core/folder/FolderListGroupItem/FolderListGroupItem";
import LocalEvents from "events/LocalEvents";
import { FolderType } from "model/FolderType";
import { SceneMoveButton } from "scene/ScenesOverviewPage/components/SceneMoveButton";
import { FolderNavigateUp } from "core/folder/FolderNavigateUp/FolderNavigateUp";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { GroupRole } from "core/auth/GroupRole";
import { SceneCopyDropdownItem } from "scene/ScenesOverviewPage/components/SceneCopyDropdownItem";
import { OverflowMenu } from "core/components/OverflowMenu/OverflowMenu";

interface ScenesListProps {
    sceneListItems: FolderListItem[];
    folderId?: string;
}

export function ScenesList(props: ScenesListProps): ReactElement {
    const organizationContext = useOrganizationContext();

    function renderList() {
        return props.sceneListItems?.map(
            (folderListItem: FolderListItem, index: number): ReactElement => {
                if (folderListItem.type === "FOLDER") {
                    return (
                        <FolderListGroupItem
                            key={index}
                            folderListItem={folderListItem}
                            changeEvent={LocalEvents.SCENES_CHANGED}
                            folderType={FolderType.SCENE}
                        />
                    );
                }
                return (
                    <ListGroup.Item
                        className="d-flex justify-content-between"
                        key={index}
                    >
                        <div>
                            <Link
                                className="nav-link"
                                to={`${absoluteScenesPath}/${folderListItem.id}`}
                                data-cy="scene-list-item"
                            >
                                {folderListItem.name
                                    ? folderListItem.name
                                    : folderListItem.id}
                            </Link>
                        </div>
                        <ButtonGroup>
                            <HasGroupRole
                                groupId={organizationContext}
                                role={GroupRole.EDITOR}
                            >
                                <SceneMoveButton
                                    sceneId={folderListItem.id}
                                    currentFolderId={folderListItem.folder}
                                />
                                <OverflowMenu variant="secondary">
                                    <SceneCopyDropdownItem
                                        sceneId={folderListItem.id}
                                    />
                                    <DeleteSceneButton
                                        sceneId={folderListItem.id}
                                        variant="dropdown"
                                    />
                                </OverflowMenu>
                            </HasGroupRole>
                        </ButtonGroup>
                    </ListGroup.Item>
                );
            }
        );
    }

    return (
        <ListGroup className="nav" data-cy="scenes-list">
            <FolderNavigateUp parentFolderId={props.folderId} />
            {renderList()}
        </ListGroup>
    );
}
