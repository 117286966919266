import * as React from "react";
import { ReactElement, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "home/HomePage";
import SceneOverviewPage from "scene/ScenesOverviewPage/SceneOverviewPage";
import Header from "../../core/components/Header/Header";
import styles from "./AdminRouter.scss";
import {
    FaCalendarAlt,
    FaFont,
    FaHome,
    FaImage,
    FaLaptop,
    FaLaptopCode,
    FaNewspaper,
    FaPhotoVideo,
    FaTv,
    FaUser,
    FaUsers,
    FaVideo,
    FaHouseUser,
    FaGlobe,
    FaNetworkWired,
} from "react-icons/fa";
import { Accordion, Nav } from "react-bootstrap";
import { classNames } from "@castia/sdk";
import ChannelListPage from "channel/ChannelListPage";
import DeviceRouter from "routers/AdminRouter/sub-routers/DeviceRouter";
import Sidebar from "core/components/Sidebar/Sidebar";
import SceneEditorPage from "scene/ScenesEditorPage/SceneEditorPage";
import ChannelEditor from "channel/ChannelEditorPage";
import { toast, ToastContainer } from "react-toastify";
import ImagePage from "image/ImagePage";
import {
    absoluteFontPath,
    absoluteGroupPath,
    absoluteImagePath,
    absoluteMediaPath,
    absoluteNewsPath,
    absolutePlayerAdminPage,
    rootPath,
    absoluteSchedulePath,
    absoluteUserPath,
    absoluteVideoPath,
    absoluteTenantProfilePath,
    absoluteKeycloakRealmPath,
    absoluteWireguardPeerAdminPage,
    relativeScenesPath,
    relativeChannelsPath,
    relativeDevicesPath,
    relativeSchedulePath,
    relativeMediaPath,
    relativeImagePath,
    relativeVideoPath,
    relativeFontPath,
    relativePlayerManagementPath,
    relativeUserPath,
    relativeNewsPath,
    relativeProfilePath,
    relativeTenantProfilePath,
    relativeKeycloakRealmPath,
    relativeWireguardPeerPath,
    relativeGroupPath,
    relativePlanningPath,
    systemRootPath,
} from "core/util/routes";
import { SchedulePage } from "schedule/SchedulePage";
import { ScheduleDetailPage } from "schedule/ScheduleDetailPage";
import { VideoPage } from "video/VideoPage";
import { IsSysAdmin } from "core/auth/IsSysAdmin";
import { PlayerAdminPage } from "sysadmin/PlayerAdminPage/PlayerAdminPage";
import { GroupSelect } from "core/auth/GroupSelect";
import { UserRouter } from "routers/AdminRouter/sub-routers/UserRouter";
import useAuthentication from "hooks/useAuthentication";
import { ShowIf } from "core/util/ShowIf";
import { GroupRouter } from "routers/AdminRouter/sub-routers/GroupRouter";
import { HasRole } from "core/auth/HasRole";
import { Role } from "core/auth/Role.enum";
import usePlugins from "hooks/usePlugins";
import { PluginPageComponent } from "core/components/Plugin/PluginPageComponent";
import { useHasGroupRoleOnAnyGroup } from "core/auth/useHasGroupRoleOnAnyGroup";
import { GroupRole } from "core/auth/GroupRole";
import { useIsAdmin } from "core/auth/useIsAdmin";
import { UserProfileRouter } from "routers/AdminRouter/sub-routers/UserProfileRouter";
import { findGroupInTree } from "core/auth/useHasGroupRole";
import { NewsRouter } from "./sub-routers/NewsRouter";
import { FontRouter } from "routers/AdminRouter/sub-routers/FontRouter";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { TenantProfileRouter } from "routers/AdminRouter/sub-routers/TenantProfileRouter";
import { KeycloakRealmRouter } from "routers/AdminRouter/sub-routers/KeycloakRealmRouter";
import { WireguardPeerRouter } from "routers/AdminRouter/sub-routers/WireguardPeerRouter";

const DEFAULT_TOAST_DURATION = 4000;

function AdminRouter(): ReactElement {
    const [sidebarToggled, toggle] = useState(false);
    const location = useLocation();
    const auth = useAuthentication();
    const plugins = usePlugins();
    const isReseller = useHasGroupRoleOnAnyGroup(GroupRole.RESELLER);
    const isOrganizationAdmin = useHasGroupRoleOnAnyGroup(
        GroupRole.ORGANIZATION_ADMIN
    );
    const isAdmin = useIsAdmin();
    const org = useOrganizationContext();

    useEffect(() => {
        window.addEventListener("error", (e) => {
            // Hide ResizeObserver errors for dev mode. They can trigger while running cypress tests
            if (e.message.includes("ResizeObserver")) {
                const resizeObserverErrDiv = document.getElementById(
                    "webpack-dev-server-client-overlay-div"
                );
                const resizeObserverErr = document.getElementById(
                    "webpack-dev-server-client-overlay"
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        });
    }, []);

    return (
        <>
            <Sidebar toggled={sidebarToggled}>
                <nav>
                    <ShowIf
                        condition={auth.getFlatGroups().length > 1 || isAdmin}
                    >
                        <GroupSelect />
                    </ShowIf>
                    <Nav
                        defaultActiveKey="/"
                        className={classNames(styles.sidebarNav, "flex-column")}
                    >
                        <Accordion
                            flush
                            defaultActiveKey={
                                location.pathname.includes(absoluteMediaPath)
                                    ? absoluteMediaPath
                                    : absoluteMediaPath
                            }
                        >
                            <Sidebar.MenuItem to={``} end>
                                <FaHome /> <span>Overview</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem
                                to={`scenes`}
                                data-cy="menu-scenes"
                            >
                                <FaImage /> <span>Scenes</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem to={`channels`}>
                                <FaTv /> <span>Channels</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem to={absoluteSchedulePath}>
                                <FaCalendarAlt /> <span>Scheduling</span>
                            </Sidebar.MenuItem>
                            <Accordion.Item
                                className="border-0"
                                eventKey={absoluteMediaPath}
                            >
                                <Accordion.Header className="bg-primary">
                                    <FaPhotoVideo /> <span>Media</span>
                                </Accordion.Header>
                                <Accordion.Body className="bg-primary px-3 py-0">
                                    <Sidebar.CollapseMenuItem
                                        to={absoluteImagePath}
                                    >
                                        <FaImage /> <span>Images</span>
                                    </Sidebar.CollapseMenuItem>
                                    <Sidebar.CollapseMenuItem
                                        to={absoluteVideoPath}
                                    >
                                        <FaVideo /> <span>Videos</span>
                                    </Sidebar.CollapseMenuItem>
                                    <HasGroupRole
                                        groupId={org}
                                        role={GroupRole.LOCATION_ADMIN}
                                    >
                                        <Sidebar.CollapseMenuItem
                                            to={absoluteFontPath}
                                        >
                                            <FaFont /> <span>Fonts</span>
                                        </Sidebar.CollapseMenuItem>
                                    </HasGroupRole>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Sidebar.MenuItem to={`devices`}>
                                <FaLaptop /> <span>Devices</span>
                            </Sidebar.MenuItem>
                        </Accordion>
                        <ShowIf
                            condition={
                                isReseller || isOrganizationAdmin || isAdmin
                            }
                        >
                            <Sidebar.MenuHeader>Admin</Sidebar.MenuHeader>

                            <HasRole role={Role.ADMIN}>
                                <Sidebar.MenuItem to={absoluteUserPath}>
                                    <FaUser /> <span>Users</span>
                                </Sidebar.MenuItem>
                            </HasRole>
                            <Sidebar.MenuItem to={absoluteGroupPath}>
                                <FaUsers /> <span>Groups</span>
                            </Sidebar.MenuItem>
                            <HasRole role={Role.ADMIN}>
                                <Sidebar.MenuItem to={absoluteNewsPath}>
                                    <FaNewspaper /> <span>News</span>
                                </Sidebar.MenuItem>
                            </HasRole>
                        </ShowIf>

                        <IsSysAdmin>
                            <Sidebar.MenuHeader>
                                System Admin
                            </Sidebar.MenuHeader>
                            <Sidebar.MenuItem to={absoluteTenantProfilePath}>
                                <FaHouseUser /> <span>Tenant Profiles</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem to={absoluteKeycloakRealmPath}>
                                <FaGlobe /> <span>Keycloak Realms</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem to={absolutePlayerAdminPage}>
                                <FaLaptopCode /> <span>Player Admin</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem
                                to={absoluteWireguardPeerAdminPage}
                            >
                                <FaNetworkWired />{" "}
                                <span>WireGuard Peer Entries</span>
                            </Sidebar.MenuItem>
                        </IsSysAdmin>
                        {plugins.pages?.length > 0 && (
                            <>
                                <Sidebar.MenuHeader>Plugins</Sidebar.MenuHeader>
                                {plugins.pages.map((page) => {
                                    return (
                                        <ShowIf
                                            key={page.key}
                                            condition={page.accessControl(
                                                auth.getRoles(),
                                                auth.organizationContext,
                                                findGroupInTree(
                                                    auth.organizationContext,
                                                    auth.getGroups()
                                                ),
                                                auth.getFlatGroups(),
                                                auth.getGroups
                                            )}
                                        >
                                            <Sidebar.MenuItem
                                                to={`${rootPath}/${page.key}${page.path}`}
                                            >
                                                <page.menuIcon />{" "}
                                                <span>{page.menuTitle}</span>
                                            </Sidebar.MenuItem>
                                        </ShowIf>
                                    );
                                })}
                            </>
                        )}
                    </Nav>
                </nav>
            </Sidebar>

            <div className={styles.notHeader}>
                <Header
                    onMenuToggleClick={(): void => toggle(!sidebarToggled)}
                />

                <div className={styles.pageContentWrapper}>
                    <div className={styles.content}>
                        <Routes>
                            {/* The components will be rendered here because they're sub-components of the <Switch> in the index.tsx */}
                            <Route path={``} element={<HomePage />} />
                            <Route path={`home`} element={<HomePage />} />
                            <Route
                                path={`${relativeScenesPath}`}
                                element={<SceneOverviewPage />}
                            />
                            <Route
                                path={`${relativeScenesPath}/:scene`}
                                element={<SceneEditorPage />}
                            />
                            <Route
                                path={relativeChannelsPath}
                                element={<ChannelListPage />}
                            />
                            <Route
                                path={`${relativeChannelsPath}/:channel`}
                                element={<ChannelEditor />}
                            />
                            <Route
                                path={`${relativeDevicesPath}/*`}
                                element={<DeviceRouter />}
                            />
                            <Route path={relativePlanningPath}>
                                <Route
                                    path={`${relativeSchedulePath}`}
                                    element={<SchedulePage />}
                                />
                                <Route
                                    path={`${relativeSchedulePath}/:scheduleId`}
                                    element={<ScheduleDetailPage />}
                                />
                            </Route>
                            <Route path={`${relativeMediaPath}/*`}>
                                <Route
                                    path={`${relativeImagePath}`}
                                    element={<ImagePage />}
                                />
                                <Route
                                    path={`${relativeVideoPath}`}
                                    element={<VideoPage />}
                                />
                                <Route
                                    path={`${relativeFontPath}/*`}
                                    element={<FontRouter />}
                                />
                            </Route>
                            <Route path={systemRootPath}>
                                <Route
                                    path={relativePlayerManagementPath}
                                    element={<PlayerAdminPage />}
                                />
                                <Route
                                    path={`${relativeWireguardPeerPath}/*`}
                                    element={<WireguardPeerRouter />}
                                />
                            </Route>
                            <Route
                                path={`${relativeUserPath}/*`}
                                element={<UserRouter />}
                            />
                            <Route
                                path={`${relativeNewsPath}/*`}
                                element={<NewsRouter />}
                            />
                            <Route
                                path={`${relativeProfilePath}/*`}
                                element={<UserProfileRouter />}
                            />
                            <Route
                                path={`${relativeTenantProfilePath}/*`}
                                element={<TenantProfileRouter />}
                            />
                            <Route
                                path={`${relativeKeycloakRealmPath}/*`}
                                element={<KeycloakRealmRouter />}
                            />
                            <Route
                                path={`${relativeGroupPath}/*`}
                                element={<GroupRouter />}
                            />
                            {plugins.pages?.map((page) => {
                                return (
                                    <Route
                                        path={`${page.key}${page.path}/*`}
                                        key={page.key}
                                        element={
                                            <PluginPageComponent page={page} path={`${rootPath}/${page.key}${page.path}`} />
                                        }
                                    />
                                );
                            })}
                        </Routes>
                    </div>
                </div>
            </div>
            <ToastContainer
                autoClose={DEFAULT_TOAST_DURATION}
                position={toast.POSITION.BOTTOM_CENTER}
                className={styles.toast}
            />
        </>
    );
}

export default AdminRouter;
