import useFetch, { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import { PublicTenantProfile } from "model/PublicTenantProfile";

export function usePublicTenantProfile(): FetchData<PublicTenantProfile> {
    return useFetch<PublicTenantProfile>(
        `${environment.apiBaseUrl}/api/v1/context`,
        null,
        { skipAuth: true }
    );
}
