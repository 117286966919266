import { useDeviceScreenshots } from "hooks/api/device/useDeviceScreenshots";
import { ResponseErrorHandler } from "@castia/sdk";
import React, { ReactElement, useState } from "react";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "device/show/DeviceScreenshotCarouselCard.scss";
import { FaExpand, FaQuestionCircle } from "react-icons/fa";
import { DeviceScreenshotCarousel } from "device/show/DeviceScreenshotCarousel";
import { FullscreenOverlay } from "core/components/FullscreenOverlay/FullscreenOverlay";
import { TooltipProps } from "react-bootstrap/Tooltip";

interface DeviceScreenshotCarouselProps {
    deviceId: string;
}

export function DeviceScreenshotCarouselCard(
    props: DeviceScreenshotCarouselProps
): ReactElement {
    const { response, isLoading, error } = useDeviceScreenshots(props.deviceId);
    const [activeItem, setActiveItem] = useState(0);
    const [fullscreen, setFullscreen] = useState(false);

    const renderTooltip = (tooltipProps: TooltipProps & React.RefAttributes<HTMLDivElement>) => (
        <Tooltip {...tooltipProps}>
            The screenshots are not 100% accurate and are only shown as an
            indication of what was on the screen. It can happen that an image is
            missing or a layout is shown slightly differently from reality.
        </Tooltip>
    );

    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    <div className={styles.header}>
                        <span className={styles.titleContainer}>
                            Screenshots
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <span className={styles.disclaimer}>
                                    <FaQuestionCircle />
                                </span>
                            </OverlayTrigger>
                        </span>
                        {response && response.length > 0 && (
                            <Button
                                variant="link"
                                aria-label="Fullscreen"
                                onClick={() => setFullscreen(true)}
                            >
                                <FaExpand />
                            </Button>
                        )}
                    </div>
                </Card.Title>
                <ResponseErrorHandler isLoading={isLoading} error={error}>
                    {response && response.length > 0 && (
                        <>
                            <DeviceScreenshotCarousel
                                screenshots={response}
                                setActiveItem={setActiveItem}
                                activeItem={activeItem}
                            />
                            {fullscreen && (
                                <FullscreenOverlay
                                    onClose={() => setFullscreen(false)}
                                    backgroundColor="black"
                                >
                                    <DeviceScreenshotCarousel
                                        screenshots={response}
                                        setActiveItem={setActiveItem}
                                        activeItem={activeItem}
                                        fullscreen
                                    />
                                </FullscreenOverlay>
                            )}
                        </>
                    )}
                    {response && response.length === 0 && (
                        <span>No screenshots for this device yet.</span>
                    )}
                </ResponseErrorHandler>
            </Card.Body>
        </Card>
    );
}
