import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React from "react";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { FaPlus } from "react-icons/fa";
import { absoluteTenantProfilePath } from "core/util/routes";
import { TenantProfileListContainer } from "tenant-profile/list/TenantProfileListContainer";

export function TenantProfileListPage() {
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>
                    Tenant Profiles
                </PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <LinkButton
                        to={`${absoluteTenantProfilePath}/create`}
                        variant="primary"
                        size="md"
                    >
                        <FaPlus /> Create Tenant Profile
                    </LinkButton>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <TenantProfileListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}
