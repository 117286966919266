import * as React from "react";
import { ReactElement, useRef, useState } from "react";
import Button from "core/components/UI/Button/Button";
import Modal from "core/components/UI/Modal/Modal";
import { useForm } from "react-hook-form";
import useCreateScene from "hooks/api/scene/useCreateScene";
import Error from "core/components/UI/Error/Error";
import { SceneCreateFormStep1 } from "scene/ScenesOverviewPage/components/SceneCreator/SceneCreateFormStep1";
import { SceneCreateFormStep2 } from "scene/ScenesOverviewPage/components/SceneCreator/SceneCreateFormStep2";
import usePlugins from "hooks/usePlugins";
import { useNavigate } from "react-router-dom";
import { absoluteScenesPath } from "core/util/routes";
import { useQuery } from "hooks/useQuery";
import { FolderIdParams } from "core/folder/FolderIdParams";

export interface SceneCreateFormType {
    title: string;
    template: string;
    layout?: string;
}

function SceneCreateModal(): ReactElement {
    const [showModal, setShowModal] = useState(false);
    const form = useForm();
    const { sendRequest, error, reset } = useCreateScene();
    const formData = useRef<Partial<SceneCreateFormType>>({});
    const [currentStep, setCurrentStep] = useState<number>(0);
    const { findTemplate } = usePlugins();
    const navigate = useNavigate();
    const { folderId } = useQuery<FolderIdParams>();

    async function onSubmit(data: Partial<SceneCreateFormType>): Promise<void> {
        Object.assign(formData.current, data);

        const result = await sendRequest({
            title: formData.current.title,
            template: formData.current.template,
            layout: formData.current.layout || null,
            folder: folderId,
        });
        if (result) {
            form.reset();
            reset();
            navigate(`${absoluteScenesPath}/${result.id}`);
        }
    }

    async function completeStep1(data: Partial<SceneCreateFormType>) {
        if (findTemplate(data.template).layouts.length === 1) {
            // If there is only one layout, skip step 2 and just submit the data now.
            await onSubmit(data);

            return;
        }

        Object.assign(formData.current, data);
        setCurrentStep((prev) => ++prev);
    }

    function renderStep(stepNumber: number) {
        switch (stepNumber) {
            case 0:
                return (
                    <SceneCreateFormStep1
                        onSubmit={completeStep1}
                        initialData={formData.current}
                        previous={() => setShowModal(false)}
                    />
                );
            case 1:
                return (
                    <SceneCreateFormStep2
                        onSubmit={onSubmit}
                        initialData={formData.current}
                        template={formData.current.template}
                        previous={() => setCurrentStep((prev) => --prev)}
                    />
                );
        }
    }

    return (
        <>
            <Button
                variant={"primary"}
                size={"sm"}
                onClick={(): void => setShowModal(true)}
                title="New scene"
                data-cy="create-scene-button"
            >
                New Scene
            </Button>
            <Modal
                show={showModal}
                onHide={(): void => setShowModal(false)}
                size={"xl"}
                title="Create new scene"
                data-cy="create-scene-modal"
            >
                {error && (
                    <Error>Something went wrong while saving the scene.</Error>
                )}

                {renderStep(currentStep)}
            </Modal>
        </>
    );
}

export default SceneCreateModal;
