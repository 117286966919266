import { Dropdown } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import { absoluteProfilePath } from "core/util/routes";

export function AccountProfileLink() {
    const navigate = useNavigate();

    return (
        <Dropdown.Item
            onClick={() => {
                navigate(absoluteProfilePath);
            }}
        >
            Profile
        </Dropdown.Item>
    );
}
