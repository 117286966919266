import * as React from "react";
import { ScenesList } from "scene/ScenesOverviewPage/components/ScenesListContainer/ScenesList/ScenesList";
import { ReactElement, useEffect, useState } from "react";
import useFetchAllScenesPaginated, {
    defaultScenePageSize,
} from "hooks/api/scene/useFetchAllScenesPaginated";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import { Paging } from "@castia/sdk";
import { useQuery } from "hooks/useQuery";
import styles from "scene/ScenesOverviewPage/components/ScenesListContainer/ScenesListContainer.scss";
import { FolderIdParams } from "core/folder/FolderIdParams";

/**
 * Container which retrieves and manages the scene list.
 * @constructor
 */
function ScenesListContainer(): ReactElement {
    const { folderId } = useQuery<FolderIdParams>();
    const { isLoading, response, error, refreshData } =
        useFetchAllScenesPaginated(folderId, null);
    // Set folderId via state, otherwise it re-renders too often.
    const [stateFolderId, setStateFolderId] = useState(folderId);

    useEffect(() => {
        if (folderId) {
            setStateFolderId(folderId);
        } else {
            setStateFolderId(null);
        }
    }, [folderId]);

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            <ScenesList
                sceneListItems={response?.items}
                folderId={stateFolderId}
            />
            <div className={styles.pagingContainer}>
                {response && (
                    <Paging
                        currentPage={response?.meta.currentPage}
                        onClick={(page): void => {
                            refreshData(
                                new URLSearchParams({
                                    page: String(page),
                                    limit: String(defaultScenePageSize),
                                })
                            );
                        }}
                        totalPages={response?.meta.totalPages}
                        showMax={10}
                    />
                )}
            </div>
        </ResponseErrorHandler>
    );
}

export default ScenesListContainer;
