import { Route } from "react-router";
import React, { ReactElement } from "react";
import { KeycloakRealmCreatePage } from "keycloak-realm/KeycloakRealmCreatePage";
import { KeycloakRealmShowPage } from "keycloak-realm/KeycloakRealmShowPage";
import { KeycloakRealmEditPage } from "keycloak-realm/KeycloakRealmEditPage";
import { KeycloakRealmListPage } from "keycloak-realm/KeycloakRealmListPage";
import { Routes } from "react-router-dom";

export function KeycloakRealmRouter(): ReactElement {
    return (
        <Routes>
            <Route path="create" element={<KeycloakRealmCreatePage />} />
            <Route
                path=":keycloakRealmId"
                element={<KeycloakRealmShowPage />}
            />
            <Route
                path=":keycloakRealmId/edit"
                element={<KeycloakRealmEditPage />}
            />
            <Route path="" element={<KeycloakRealmListPage />} />
        </Routes>
    );
}
