import { NewsItem } from "model/NewsItem";
import React, { ReactElement, useState } from "react";
import { absoluteNewsPath } from "core/util/routes";
import { ButtonGroup, Table } from "react-bootstrap";
import { HasRole } from "core/auth/HasRole";
import { Role } from "core/auth/Role.enum";
import { FaCheck, FaEdit, FaTimes } from "react-icons/fa";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { NewsShowModal } from "news/show/NewsShowModal";
import { NewsDeleteButton } from "news/delete/NewsDeleteButton";
import { formatDate } from "core/util/dateTimeUtil";

interface NewsListProps {
    newsItems: NewsItem[];
}

export function NewsList(props: NewsListProps) {
    const [show, setShow] = useState(false);
    const [clickedItem, setClickedItem] = useState(null);

    const newsList = props.newsItems.map(
        (newsItem: NewsItem, index: number): ReactElement => {
            return (
                <tr key={index}>
                    <td>
                        <span
                            className="nav nav-link"
                            role="button"
                            onClick={() => {
                                setClickedItem(newsItem);
                                setShow(true);
                            }}
                        >
                            {newsItem.title}
                        </span>
                    </td>
                    <td>{formatDate(new Date(newsItem.createdAt))}</td>

                    <HasRole role={Role.ADMIN}>
                        <td>
                            {newsItem.published ? <FaCheck /> : <FaTimes />}
                        </td>
                        <td className="text-end">
                            <ButtonGroup>
                                <LinkButton
                                    to={`${absoluteNewsPath}/${newsItem.id}/edit`}
                                    size="md"
                                >
                                    <FaEdit />
                                </LinkButton>
                                <NewsDeleteButton newsItemId={newsItem.id} />
                            </ButtonGroup>
                        </td>
                    </HasRole>
                </tr>
            );
        }
    );

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Date</th>
                        <HasRole role={Role.ADMIN}>
                            <th>Published</th>
                            <th></th>
                        </HasRole>
                    </tr>
                </thead>
                <tbody>{newsList}</tbody>
            </Table>
            <NewsShowModal
                show={show}
                setShowModal={setShow}
                newsItem={clickedItem}
            />
        </>
    );
}
